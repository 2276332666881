<template>
    <div class="task-content">
        <div class="sort-list">
            <div class="item">
                <span class="sort-text">类型分类：</span>
                <span class="sort-item" :class="{current: currentSort === -1}" @click="chooseSort(-1)">全部</span>
                <span class="sort-item"
                      :class="{current: currentSort === sortItem.id}"
                      v-for="sortItem in sortList"
                      :key="sortItem.id"
                      @click="chooseSort(sortItem.id)">
                    {{sortItem.name}}
                </span>
            </div>
            <div class="item">
                <span class="sort-text">任务筛选：</span>
                <span class="sort-item" :class="{current: currentSelect === -1}" @click="chooseSelect(-1)">全部</span>
                <span class="sort-item"
                      :class="{current: currentSelect === selectItem.id}"
                      v-for="selectItem in selectTask"
                      :key="selectItem.id"
                      @click="chooseSelect(selectItem.id)">
                    {{selectItem.name}}
                </span>
            </div>
            <div class="item">
                <span class="sort-text">条件筛选：</span>
                <span class="sort-item" :class="{current: currentCondition === -1}" @click="chooseCondition(-1)">全部</span>
                <span class="sort-item"
                      :class="{current: currentCondition === selectItem.id}"
                      v-for="selectItem in selectCondition"
                      :key="selectItem.id"
                      @click="chooseCondition(selectItem.id)">
                    {{selectItem.name}}
                </span>
            </div>
        </div>
        <div class="task-page">
            <div class="task-list" v-if="teacherTaskList.length !== 0">
                <div class="list-item" v-for="item in teacherTaskList" @click="viewTaskDetail(item)">
                    <div class="container">
                        <div class="item-cover">
                            <img :src="item.task_cover" alt="">
                            <!--<img v-if="item.enroll" src="../../assets/img/image/no-apply.png" class="sign" alt="">-->
                            <img v-if="item.task_type === 1" src="../../assets/img/image/teacher-task-1.png" class="sign" alt="">
                            <img v-if="item.task_type === 3" src="../../assets/img/image/teacher-task-3.png" class="sign" alt="">
                            <img v-if="item.task_type === 4" src="../../assets/img/image/teacher-task-4.png" class="sign" alt="">
                        </div>
                        <div class="item-content">
                            <span class="item-title">{{item.task_title}}</span>
                            <div class="item-info">
                                <div class="item-author">
                                    <div class="item-avatar">
                                        <img :src="item.head_portrait ? item.head_portrait : require('../../assets/img/image/user_img.png')" alt="">
                                        <span>{{item.name}}</span>
                                    </div>
                                    <span>{{item.create_time}}</span>
                                </div>
                                <div class="item-evaluate">
                                    <!--<div class="evaluate-status">-->
                                        <!--评价状态:-->
                                        <!--<span><i class="iconfont" v-if="item.status === 0">&#xe63f;</i>{{item.evaluate_status}}</span>-->
                                    <!--</div>-->
                                    <div class="evaluate-num">
                                        参与班级:
                                        <span v-for="classItem in item.class_info">{{classItem.name}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item-status">
                            <span class="view-task" v-if="item.state === 2">查看任务 ></span>
                            <span class="finish-task" v-if="item.state === 1">已完成</span>
                            <span class="time-out-task" v-if="item.state === 0">已截止</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="task-list no-task-list">
                <span>暂无数据</span>
            </div>
            <el-pagination
                    v-if="teacherTaskList.length > 0"
                    class="pages-center"
                    :current-page="taskPages.currentPageNum"
                    :page-size="taskPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="taskPages.total"
                    @current-change="taskCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TeacherTask",
        data() {
            return {
                //类型分类
                sortList:[],
                currentSort: -1,
                //任务筛选
                selectTask: [
                    {
                        id: 0,
                        name: '已截止'
                    },
                    {
                        id: 1,
                        name: '已完成'
                    },
                    {
                        id: 2,
                        name: '未完成'
                    }
                ],
                currentSelect: -1,
                //条件筛选
                selectCondition: [
                    {
                        id: 1,
                        name: '普通任务'
                    },
                    {
                        id: 3,
                        name: '实训任务'
                    },
                    {
                        id: 4,
                        name: '脚本任务'
                    }
                ],
                currentCondition: -1,
                //学生端获取的任务列表：包括教师任务和企业任务
                teacherTaskList: [],
                //分页
                taskPages: {
                    currentPageNum: 1,
                    eachPageNum: 12,
                    total: 0
                },
            }
        },
        mounted() {
            this.getTaskType();
            this.getTeacherTaskList();
        },
        methods: {
            //类型分类
            getTaskType() {
                this.$httpStudent.axiosGet(this.$api.classification, (res) => {
                    if (res.code === 200) {
                        this.sortList = res.data.data;
                    } else {
                        this.$message.error(res.message);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //选择任务分类
            chooseSort(id) {
                this.currentSort = id;
                this.getTeacherTaskList();
            },
            //任务筛选
            chooseSelect(id) {
                this.currentSelect = id;
                this.getTeacherTaskList();
            },
            //条件筛选
            chooseCondition(id) {
                this.currentCondition = id;
                this.getTeacherTaskList();
            },
            //任务列表
            getTeacherTaskList() {
                let param = {
                    type: 1,
                    page: this.taskPages.currentPageNum,
                    limit: this.taskPages.eachPageNum
                }
                if (this.currentSort === -1) {
                    delete param.task_type_id
                } else {
                    param.task_type_id = this.currentSort;
                }

                if (this.currentSelect === -1) {
                    delete param.task_state
                } else {
                    param.task_state = this.currentSelect;
                }

                if (this.currentCondition === -1) {
                    delete param.task_type
                } else {
                    param.task_type = this.currentCondition;
                }

                this.$httpStudent.axiosGetBy(this.$api.studentTaskList, param, (res) => {
                    if (res.code === 200) {
                        this.teacherTaskList = res.list;
                        this.taskPages.total = res.total;
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //分页
            taskCurrentChange(val){
                this.taskPages.currentPageNum = val;
                this.getTeacherTaskList();
            },
            //查看任务详情
            viewTaskDetail(item) {
                this.$router.push({
                    path: '/student/task/detail',
                    query: {
                        id: item.task_id
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .task-content {
        height: 100%;
        min-height: calc(100vh - 172px);
        display: flex;
        flex-direction: column;
        .sort-list {
            padding-left: 10px;
            .item {
                padding: 0 10px;
                margin: 0 0 15px 0;
                color: #666;
                cursor: pointer;
            }
            .sort-item {
                display: inline-block;
                padding: 2px 6px;
                margin: 0 5px;
            }
            .sort-item.current, .sort-item:hover {
                background: #BEC3F1;
                color: #2338E6;
                border-radius:4px;
            }
        }
        .task-page {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            height: 1%;
        }
        .task-list {
            /*margin-top: 20px;*/
            padding-left: 24px;
            margin-right: -20px;
            display: flex;
            flex-wrap: wrap;
            .list-item {
                width: calc(16% - 14px);
                margin: 20px 20px 0 0;
                border-radius: 8px;
                .container {
                    width: 100%;
                    border: 1px solid #E3E4EC;
                    transition: all .25s ease-out;
                    border-radius: 8px;
                    cursor: pointer;
                    .item-cover {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 138px;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                        img.sign {
                            position: absolute;
                            border-radius: 0;
                            top: 25px;
                            left: 0;
                            opacity: .8;
                            width: 80px;
                        }
                    }
                    .item-content {
                        padding: 10px;
                        .item-title {
                            display: block;
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                        }
                        .item-info {
                            .item-author {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                .item-avatar {
                                    display: flex;
                                    align-items: center;
                                    margin: 10px 0;
                                    img {
                                        width: 24px;
                                        height: 24px;
                                        margin-right: 10px;
                                        border-radius: 50%;
                                    }
                                    span {
                                        display: block;
                                        width: 50px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                }
                            }
                            .item-evaluate {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                font-size: 12px;
                                i {
                                    color: #F19149;
                                    margin-right: 2px;
                                }
                            }
                        }
                    }
                    .item-status {
                        height: 38px;
                        line-height: 38px;
                        text-align: center;
                        margin: 0 10px;
                        border-top: 1px solid #E5E5E5;
                        .view-task {
                            color: #2338E6;
                        }
                        .finish-task {
                            color: #20C997;
                        }
                        .time-out-task {
                            color: #666666;
                        }
                    }
                }
            }
        }
        .no-task-list {
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .pages-center {
            margin-bottom: 10px;
        }
    }
</style>